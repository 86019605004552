<template>
    <el-dialog
        title="选择仓库"
        :visible.sync="dialogShow"
        width="1200px"
        custom-class="store-dialog"
        :before-close="handleClose"
        append-to-body
        :close-on-press-escape="false"
    >
        <div class="store-dialog-content">
            <div class="item item-form">
                <div class="item-header">筛选条件</div>
                <el-form class="form-box" label-width="100px" size="mini">
                    <el-form-item label="仓库名称">
                        <el-input
                            v-model="form.title"
                            clearable
                            @keydown.native.enter="searchList"
                            placeholder="请填写仓库名称,多个以空格分隔"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="仓库编号">
                        <el-input
                            v-model="form.code"
                            clearable
                            @keydown.native.enter="searchList"
                            placeholder="请填写仓库编号,多个以空格分隔"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="form.warehouseTypeList" filterable multiple collapse-tags clearable>
                            <el-option v-for="item in typeList" :key="item.value" :label="item.name" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="form.delState" filterable  collapse-tags clearable>
                            <el-option
                                v-for="item in stateList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                  <el-form-item label="启用状态">
                    <el-select v-model="form.isEnable" filterable collapse-tags clearable>
                      <el-option v-for="item in isEnableList" :key="item.value" :label="item.name" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                    <el-form-item class="form-footer">
                        <el-button @click="resetForm">重置</el-button>
                        <el-button type="primary" @click="searchList">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="item item-list">
                <div class="item-header">
                    未选择仓库<span class="item-header-num">共（{{ total || 0 }}）</span>
                </div>
                <el-table
                    ref="tableIns"
                    :data="list"
                    border
                    class="table-box"
                    @selection-change="listSelectionChange"
                    height="520px"
                    v-loading="loading"
                    @row-dblclick="(row) => rowDblclick(row, 'add')"
                    @row-click="(row, col, event) => rowClick(row, col, event, 'tableIns')"
                >
                    <el-table-column align="center" type="selection" width="48" v-if='multiple'></el-table-column>
                    <el-table-column align="center" prop="code" label="编号" width="95" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="名称" width="140" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="delFlag"
                        label="状态"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <el-tag size="mini" :type="scope.row.delFlag == '0' ? 'success' : 'info'">{{
                                scope.row.delFlag == '0' ? '启用' : '禁用'
                            }}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    small
                    background
                    @current-change="handleCurrentChange"
                    @size-change="handleSizChange"
                    :current-page="page"
                    :page-size="pageSize"
                    :pager-count="5"
                    :page-sizes="[100, 200, 500, 1000]"
                    layout="sizes,prev,pager,next"
                    :total="total"
                >
                </el-pagination>
            </div>
            <div class="item item-btns">
                <div style="color:red;margin:10px 0px">{{multiple?'多选模式': '单选模式'}}</div>
                <el-button type="info" size="small" plain class="btn-item" @click="checkAll" :disabled="!multiple">
                    <svg-icon class-name="size-icon" icon-class="shift-right" />
                    <span>全选</span>
                </el-button>
                <el-button type="info" size="small" plain class="btn-item" @click="check" :disabled="!multiple">
                    <svg-icon class-name="size-icon" icon-class="shift-right" />
                    <span>右移</span>
                </el-button>
                <el-button type="info" size="small" plain class="btn-item" @click="remove" :disabled="!multiple">
                    <svg-icon class-name="size-icon" icon-class="shift-left" />
                    <span>左移</span>
                </el-button>
                <el-button type="info" size="small" plain class="btn-item" @click="removeAll" :disabled="!multiple">
                    <svg-icon class-name="size-icon" icon-class="shift-left" />
                    <span>全选</span>
                </el-button>
            </div>
            <div class="item item-checks">
                <div class="item-header">
                    已添加仓库<span class="item-header-num">共（{{ checkList.length || 0 }}）</span>
                </div>
                <el-table
                    ref="checkTableIns"
                    :data="checkList"
                    border
                    center
                    class="table-box"
                    @selection-change="checkListSelectionChange"
                    height="548px"
                    @row-dblclick="(row) => rowDblclick(row, 'del')"
                    @row-click="(row, col, event) => rowClick(row, col, event, 'checkTableIns')"
                >
                    <el-table-column align="center" type="selection" width="48" v-if='multiple'></el-table-column>
                    <el-table-column align="center" prop="code" label="编号" width="95" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="名称" width="140" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="delFlag"
                        label="状态"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <el-tag size="mini" :type="scope.row.delFlag == '0' ? 'success' : 'info'">{{
                                scope.row.delFlag == '0' ? '启用' : '禁用'
                            }}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">取 消</el-button>
            <el-button size="small" type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
const ID_KEY = 'id'
const NAME_KEY = 'title'
const CODE_KEY = 'code'
import svgIcon from '@/components/SvgIcon/index'
import { erpErpWarehouseUnitInfoQueryList } from '@/libs/http/modules/erp'

export default {
    name: 'supplierDialog',
    emits: ['confirm'],
    inject: ['multiple', 'modelKey'],
    components: { svgIcon },
    data() {
        return {
            dialogShow: false,
            loading: false,
            form: {
                title: '',
                code: '',
                type: '',
                delFlag: '',
                delState:'0',
                isEnable:'Y',
            },
          isEnableList: [
            { name: '是', value: 'Y' },
            { name: '否', value: 'N' },

          ],
            typeList: [
                {
                    name: '实体仓',
                    value: 'A'
                },
                {
                    name: '工服仓',
                    value: 'B'
                },
                {
                    name: '行政物料仓',
                    value: 'C'
                },
                {
                    name: '云仓',
                    value: 'D'
                },
                {
                    name: '虚拟仓',
                    value: 'E'
                }
            ],
            stateList: [
                { name: '全部', value: '2' },
                { name: '正常', value: '0' },
                { name: '删除', value: '1' }
            ],
            list: [],
            selectList: [],

            checkList: [],
            selectCheckList: [],
            total: 0,
            page: 1,
            pageSize: 100
        }
    },
    created() {},
    methods: {
        //打开
        show(data) {
            if (data && data.length) {
                this.checkList = data
            }
            this.dialogShow = true
        },
        //关闭
        handleClose() {
            this.dialogShow = false
            this.resetForm()
            this.list = []
            this.selectList = []
            this.selectCheckList = []
            this.checkList = []
            this.total = 0
            this.page = 1
            this.pageSize = 100
        },
        // 确认
        confirm() {
            if (!this.multiple && this.checkList.length > 1) {
                this.$message.error('单选模式,仅能选择一条数据')
                return
            }
            if (!this.checkList.length) {
                this.$message.error('请先选择数据')
                return
            }
            this.$emit('confirm', this.checkList)
            this.handleClose()
        },
        resetForm() {
            this.form = {
                title: '',
                code: '',
                type: '',
                delFlag: '',
                isEnable:'Y'
            }
        },
        searchList() {
            let reqData = {
                page: this.page,
                pageSize: this.pageSize
            }
            for (let key in this.form) {
                if (this.form[key] && this.form[key].length > 0) {
                    reqData[key] = this.form[key]
                }
            }

            this.loading = true
            erpErpWarehouseUnitInfoQueryList(reqData)
                .then((data) => {
                    this.list =
                        data.items.map((i) => {
                            i['name'] = i[NAME_KEY]
                            return {
                                id: this.modelKey ? i[this.modelKey] : i[ID_KEY],
                                name: i[NAME_KEY],
                                code: i[CODE_KEY],
                                delFlag: i['delFlag'],
                                protoData: i
                            }
                        }) || []
                    this.total = +data.totalItem || 0
                    this.loading = false
                })
                .catch((error) => {
                    this.$message.error(error)
                    this.loading = false
                })
        },
        //未选择列复选回调
        listSelectionChange(data) {
            this.selectList = data
        },
        //已选择列复选回调
        checkListSelectionChange(data) {
            this.selectCheckList = data
        },
        //全选
        checkAll() {
            this.checkList = this.uniqueFunc([...this.checkList, ...this.list], 'id')
            this.list = []
        },
        //选中
        check() {
            this.checkList = this.uniqueFunc([...this.checkList, ...this.selectList], 'id')
            this.list = this.list.filter((item) => !this.checkList.map((i) => i.id).includes(item.id))
            this.selectList = []
        },
        //取消选中
        remove() {
            this.list = this.uniqueFunc([...this.list, ...this.selectCheckList], 'id')
            this.checkList = this.checkList.filter((item) => !this.selectCheckList.map((i) => i.id).includes(item.id))
            this.selectCheckList = []
        },
        //取消全选
        removeAll() {
            this.list = this.uniqueFunc([...this.list, ...this.checkList], 'id')
            this.checkList = []
        },
        //行双击
        rowDblclick(row, type) {
            if (type === 'add') {
                if(!this.multiple) {
                    this.checkList=[row]
                }else  {
                this.checkList = this.uniqueFunc([...this.checkList, row], 'id')
                this.list = this.list.filter((item) => row.id !== item.id)}
            } else if (type === 'del') {
                this.list = this.uniqueFunc([...this.list, row], 'id')
                this.checkList = this.checkList.filter((item) => row.id !== item.id)
            }
        },
        //行单击
        rowClick(row, col, event, ref) {
            row.flag = !row.flag
            this.$refs[ref].toggleRowSelection(row, row.flag)
        },

        // 去重
        uniqueFunc(arr, uniId) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
        },

        handleCurrentChange(e) {
            this.page = e
            this.searchList()
        },
        handleSizChange(e) {
            this.page = 1
            this.pageSize = e
            this.searchList()
        },

        setSession(key, data) {
            sessionStorage.setItem(key, JSON.stringify(data))
        },
        getSession(key) {
            return JSON.parse(sessionStorage.getItem(key))
        }
    }
}
</script>

<style lang="scss" scoped>
.el-button.is-disabled {
    filter: grayscale(300%);
    background-color: #909399!important;
    // color: red !important;
}
.store-dialog {
    .el-dialog__header {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-dialog__headerbtn {
            position: inherit;
        }

        border-bottom: 1px solid #eee;
    }

    .el-dialog__body {
        padding: 10px;
        border-bottom: 1px solid #eee;
    }

    .el-dialog__footer {
        padding: 10px 20px;
    }

    .store-dialog-content {
        display: flex;
        align-items: center;
        height: 600px;

        .item {
            padding: 10px;
            display: flex;
            flex-direction: column;
            height: 100%;

            .table-box {
                flex: 1;
                width: 359px;
            }
        }

        .item-header {
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #909399;
            font-size: 14px;
            font-weight: bold;
            border: 1px solid #dfe6ec;
            border-bottom: none;
            background-color: #f9fafc;
            position: relative;

            .item-header-num {
                position: absolute;
                right: 0;
                top: 0;

                font-size: 12px;
                font-weight: 500;
                vertical-align: baseline;
            }
        }
    }

    .item-form {
        .form-box {
            border: 1px solid #dfe6ec;
            padding: 10px;
            height: 100%;
            width: 302px;
            position: relative;
        }

        .el-form-item {
            margin-bottom: 10px;
        }

        .form-footer {
            position: absolute;
            bottom: 0;
            right: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .el-table--medium .el-table__cell {
        padding: 0 !important;
    }

    .el-table-column--selection .cell {
        padding-right: 10px;
    }

    .el-table__header-wrapper {
        th.el-table__cell {
            background-color: #f9fafc;
        }
    }

    .item-btns {
        justify-content: center;
        align-items: center;
        // width: 100px;
        .btn-item {
            margin-bottom: 10px;

            & > span {
                display: flex;
                align-items: center;
            }
        }

        .el-button + .el-button {
            margin-left: 0;
        }
    }

    .size-icon {
        margin-right: 5px;
        font-size: 16px;
    }

    .item-list {
        .el-pagination {
            display: flex;
            justify-content: flex-end;
            padding: 3px 5px;
            border: 1px solid #dfe6ec;
            border-top: none;
            background-color: #f9fafc;
            position: relative;
            // .el-pagination__total {
            //     flex: 1;
            // }
            .btn-next {
                margin-right: 0;
            }
        }

        .el-pagination__sizes {
            position: absolute;
            top: 0;
            left: 0;
            flex: 1;

            .el-input__inner {
                border: none;
                border-radius: 0;
                background-color: #f9fafc;
            }
        }
    }

    .el-select--mini,
    .el-cascader--mini {
        height: 28px;
        line-height: 28px;

        .el-select__tags,
        .el-cascader__tags {
            flex-wrap: nowrap;
            align-items: center;

            .el-tag {
                max-width: 86px !important;
                position: relative;
            }
        }

        .el-select__tags,
        .el-cascader__tags input {
            min-width: 20px;
        }

        .el-input input {
            height: 28px !important;
        }

        .el-tag + .el-select__search-input {
            margin: 2px;
        }

        .el-tag + .el-cascader__search-input {
            margin: 2px 2px 2px 0;
            padding-left: 15px;
        }
    }
}
</style>
